import React from 'react';

import Breadcrumbs from '../../../components/Breadcrumbs';
import Link from '../../../components/Link';
import Seo from '../../../components/Seo';

const SotsialnoPobutoviPisniPage = ({ pageContext }) => {
  return (
    <>
      <Seo
        title="Соціально-побутові пісні"
        description="Соціально-побутові пісні — це великий масив епічних народно-пісенних творів про економічні та політичні умови життя різних соціальних груп населення, про їхню історичну роль у становленні та розвитку українського суспільства, у формуванні національних норм етики й моралі."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Соціально-побутові пісні
          </h1>
          <p className="typo-body mt-4 max-w-3xl">
            Соціально-побутові пісні — це великий масив епічних народно-пісенних творів про економічні та політичні
            умови життя різних соціальних груп населення, про їхню історичну роль у становленні та розвитку українського
            суспільства, у формуванні національних норм етики й моралі.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Жанри соціально-побутових пісень
        </h2>
        <ul className="mt-10">
          <li className="typo-h4">
            <Link to="/pisni/sotsialno-pobutovi-pisni/kozatski-pisni/">
              Козацькі пісні
            </Link>
          </li>
          <li className="typo-h4 mt-4">
            <Link to="/pisni/sotsialno-pobutovi-pisni/chumatski-pisni/">
              Чумацькі пісні
            </Link>
          </li>
          <li className="typo-h4 mt-4">
            <Link to="/pisni/sotsialno-pobutovi-pisni/kripatski-pisni/">
              Кріпацькі пісні
            </Link>
          </li>
          <li className="typo-h4 mt-4">
            <Link to="/pisni/sotsialno-pobutovi-pisni/rekrutski-ta-soldatski-pisni/">
              Рекрутські та солдатські пісні
            </Link>
          </li>
          <li className="typo-h4 mt-4">
            <Link to="/pisni/sotsialno-pobutovi-pisni/burlatski-pisni/">
              Бурлацькі пісні
            </Link>
          </li>
          <li className="typo-h4 mt-4">
            <Link to="/pisni/sotsialno-pobutovi-pisni/naimytski-ta-zarobitchanski-pisni/">
              Наймитські та заробітчанські пісні
            </Link>
          </li>
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру соціально-побутової пісні
        </h2>
        <p className="typo-body mt-4">
          Соціально-побутові пісні неоднорідні за основними жанровими ознаками (тематикою, функціями, способом
          поетичного  викладу, формою), але їх єднає те, що всі вони відбивають настрої народу, викликані тими чи іншими
          явищами суспільного життя. В умовах постійної боротьби проти соціального і національного гніту ці пісні стали
          своєрідним резонатором, що чутливо реагував на всі зміни в суспільному житті народу.
        </p>
        <p className="typo-body mt-4">
          Фольклористи визначають у цьому пісенному масиві залежно від провідних ідейно-тематичних мотивів цикли
          козацьких, кріпацьких, чумацьких, рекрутських та солдатських, бурлацьких, наймитських та заробітчанських пісень.
        </p>
        <p className="typo-body mt-4">
          Високо оцінив соціально-побутові пісні М. О. Добролюбов. Він запевняв, що “багато які з них за своєю сумною
          принадністю..., а нерідко і за оригінальною сміливістю думки справляють сильне враження”. Цей шар української
          народної пісенності послужив джерелом творів Г. Сковороди, І. Котляревського, Нечуя-Левицького, П. Мирного та
          інших письменників.
        </p>
        <p className="typo-body mt-4">
          Вийшло чимало досліджень і збірників соціально-побутової лірики. Наприклад: “Рекрутські та солдатські пісні”,
          “Наймитські та заробітчанські пісні”, “Чумацькі пісні” (у серії “Українська народна творчість” (70-80 рр.) та
          “Соціально-побутові пісні” (1985).
        </p>
      </div>
    </>
  );
};

export default SotsialnoPobutoviPisniPage;
